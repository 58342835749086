/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Fetish } from '@/store/fetish/types';

const namespace: string = 'fetish';

export default class FetishVideoChoicePage extends Vue {
	@State('fetish') fetish: Fetish | undefined;
	@Getter('getFetishCategory', {namespace}) getFetishCategory: any;
	@Getter('getNameModel', {namespace}) getNameModel: any;
	@Getter('getFetishModelVideo', {namespace}) getFetishModelVideo: any;
	@Mutation('setItemVideo', {namespace}) setItemVideo: any;
	@Action('fetchFetishModelVideo', {namespace}) fetchFetishModelVideo: any;

	selectedFetishVideo(item: number) {
		this.setItemVideo(item);
		this.$router.push({ name: 'fetish-video-chosen' }).then(r => r);
	}

	created() {
		this.fetchFetishModelVideo();
	}
	mounted() {
		console.log(this.getFetishCategory);
	}

}
